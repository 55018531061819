<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ENFJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            ENFJ merupakan mereka yang memiliki kepribadian Extraversion, Intuition, Feeling dan Judging. Didunia ini hanya ada 2-5% populasi ENFJ di dunia. Dimana menurut dimensinya, ENFJ dijelaskan sebagai seseorang yang Extraversion yaitu mereka yang memiliki hubungan atau jaringan perkenalan yang luas.
            Intuition yakni mereka yang cenderung menyukai hal abstrak dibandingkan hal detail. Feeling atau perasaan, mereka merupakan orang yang menghargai pertimbangan pribadi. Sedangkan Judgement merupakan mereka yang merencanakan kegiatan barulah menunjukan aksinya.
          </p>
          <p>
            Ciri kepribadian ENFJ adalah:
            <ul>
              <li> Benar-benar hangat dan senang memegang nilai atau perasaan tentang orang lain.</li>
              <li> Memegang nilai harmoni dan memiliki keterampilan yang sangat baik.</li>
              <li> Benci hal-hal berlogika impersonal dan analisis.</li>
              <li> Loyal dan juga jujur serta kreatif dan imajinatif.</li>
              <li> Menyukai tantangan baru dan sensitif terhadap kritik serta perpecahan.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Jangan mengukur harga diri dengan memperlakukan orang lain, dan jangan mudah kecewa jika mereka tidak seperti yang diinginkan.
              </li>
              <li>
                Belajar untuk kendalikan emosi dan jangan jadi pemarah. Apalagi jangan terlalu arogan, karena terlalu arogan menimbulkan hal yang menjadikan hal hal hancur dan bukan berhasil.
              </li>
              <li>
                Pekerjaan yang cocok diantaranya adalah Pekerja sosial, manajer, pengelola SDM, penulis, fasilitator, psikolog, konsultan dan juga politikus.
              </li>
            </ul>
          </p>
          <p>
            Pasangan yang cocok untuk ENFJ adalah mereka yang INFP atau ISFP. Namun hal ini tidak bisa menghalangi anda untuk memilih pasangan yang cocok tanpa melihat MBTI dan pilihlah pasangan yang anda cintai.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ENFJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ENFJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ENFJ',
        slug: 'enfj'
      }
    }
  }
};
</script>

<style scoped>

</style>
